import 'focus-visible'
import '@ingka/base/dist/style.css'
import '@ingka/focus/dist/style.css'
import '@ingka/grid/dist/style.css'
import '../globals.css'

import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'

import { Layout } from '~/components/layout'
import { Toaster } from '~/components/ui/toaster'
import AppProviders from '~/context/app-providers'

function AsIsShopManager({ Component, pageProps, router }: AppProps) {
  return (
    <AppProviders>
      <Layout locale={router.locale}>
        <Component {...pageProps} />
        <Toaster />
      </Layout>
    </AppProviders>
  )
}

export default appWithTranslation(AsIsShopManager)
