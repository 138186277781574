import { Noto_Sans_JP } from 'next/font/google'

import { notoIkea } from '~/font-loader'

const notoJp = Noto_Sans_JP({ subsets: [] })

export function Layout({
  children,
  locale,
}: {
  children: React.ReactNode
  locale?: string
}) {
  const hasJaLangAttribute =
    typeof window !== 'undefined' &&
    window.document.documentElement.lang === 'ja'
  const isJapanese = locale === 'ja' || hasJaLangAttribute
  const fontClass = isJapanese ? notoJp.className : notoIkea.className

  return <div className={fontClass}>{children}</div>
}
