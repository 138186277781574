import { TourProvider } from '@reactour/tour'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { TooltipProvider } from '~/components/ui/tooltip'
import { Badge } from '~/features/onboarding-guide/badge'
import { homeSteps, styles } from '~/features/onboarding-guide/steps'

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
})

function AppProviders({
  children,
  queryClient = defaultQueryClient,
}: {
  children: React.ReactNode
  queryClient?: QueryClient
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <TourProvider
          disableInteraction
          highlightedMaskClassName="stroke-red"
          steps={homeSteps}
          styles={styles}
          badgeContent={Badge}
        >
          {children}
        </TourProvider>
      </TooltipProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppProviders
